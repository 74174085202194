<template>
    <div>
        <div class="box">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">{{ $t('invoice.stats_invoices') }}</h3>
                </div>
                <div class="col-auto">
                    <b-button v-b-toggle.invoiceStat variant="primary" class="mb-0" :disabled="table_busy || !stats_loaded">{{ $t('invoice.see_stats') }} 
                        <span v-if="table_busy || !stats_loaded">
                            <font-awesome-icon :icon="['fas', 'spinner']" pulse />
                        </span>
                        <span v-else>
                            <font-awesome-icon :icon="['fal', 'angle-down']" />
                            <font-awesome-icon :icon="['fal', 'angle-up']" />
                        </span>
                    </b-button>
                </div>
            </div>
            <b-collapse v-if="stats_loaded" id="invoiceStat">
                <div class="row pt-4">
                    <b-table striped hover :items="stat" :fields="fields_stat">
                        <template #head()="scope">
                            <div :class="tdAlign(scope.label)">
                                {{ scope.label }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-collapse>
        </div>

        <div class="box">

            <DateRangeV2
                v-if="!horse_id"
                :start.sync="start_date"
                :end.sync="end_date"
                @submit="loadInvoices"
                :ShowTodayButton="true"
                :dateSelect="true"
                persistId="InvoiceList"
                :periodToShow="['personalise','jour','mois','mois_dernier']"
            />

            <CustomTable
                :id_table="id_table"
                :ref="id_table"
                :items="invoices"
                :busy.sync="table_busy"
                primaryKey="invoice_id"
                :hrefsRoutes="config_table_hrefs"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
				:display_action_button="!horseFicheReadOnly"
				:checkboxes="!horseFicheReadOnly"
				:externSlotColumns="extern_slot_columns"
                :columsAddBegin="['preview']"
            >
				<template v-slot:[`custom-slot-cell(tva_intra)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.tva_intra" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
				<template v-slot:[`custom-slot-cell(tva_tiers)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.tva_tiers" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
				<template v-slot:[`custom-slot-cell(invoice_exported)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.invoice_exported" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
				<template v-slot:[`custom-slot-cell(invoice_printed)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.invoice_printed" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
                <template v-slot:[`custom-slot-cell(invoice_saillie)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.invoice_saillie" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
                <template v-slot:[`custom-slot-cell(invoice_renew)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.invoice_renew" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
                <template v-slot:custom-slot-cell(horses)="{ data }">
                    <span v-if="data.horses.length < 4" >
                        <span v-for="horse, index in data.horses" :key="index">
                            <router-link :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                {{horse.horse_name}}
                            </router-link>
                            <span v-if="index != data.horses.length-1">, </span>
                        </span>
                    </span>
                    <span v-else>
                        <span :id="data.invoice_num">
                            <span v-for="horse, index in data.horses" :key="index">
                                <router-link  :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                    <span v-if="index < 3">{{horse.horse_name}}</span>
                                </router-link>
                                <span v-if="index < 2">, </span>
                            </span>...
                        </span>
                        <b-popover
                            :target="data.invoice_num"
                            triggers="hover"
                            placement="right"
                        >
                            <router-link v-for="horse in data.horses" :key="horse.horse_id" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                {{horse.horse_name}}<br>
                            </router-link>
                        </b-popover>
                    </span>
                </template>
                <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
                    <a href="" class="" @click.prevent="quickPreview(data.invoice_id)">
					    <font-awesome-icon :icon="['fas', 'eye']" />
                    </a>
				</template>
                <template v-slot:custom-slot-cell(contracts)="{ data }">
                    <span v-for="(contract, idx) in data.contracts" class="d-flex align-items-center" :key="data.invoice_id+'_'+idx+'_'+contract.contract_id">
                        <router-link :to="{ name: 'ContractFormv2', params: { contract_id: contract.contract_id }}">
                            {{ contract.contract_num }}
                        </router-link>
                    </span>
                </template>
                <template v-slot:custom-slot-cell(trackings_link)="{ data }">
                    <a href="" class="" @click.prevent="openShutterTrackings(data.invoice_id)">{{ $t('global.see_trackings') }}</a>
                </template>
                <template v-slot:[`custom-slot-cell(tracking_envoie_date)`]="{ data }">
                    <!-- <span v-html="data.tracking_envoie_date"></span>

                    <template v-if="data.mandrill_tracking">
                        <font-awesome-icon class="text-danger ml-1" :id="'tooltip-target-'+data.invoice_id" :icon="['fal', 'exclamation-triangle']"/>
                        <b-tooltip placement="right" :target="'tooltip-target-'+data.invoice_id" triggers="hover" style="min-width:500px; !important">
                            {{ data.mandrill_tracking }}
                        </b-tooltip>
                    </template-->

                    <div v-for="(tracking, index) in data.mandrill_tracking" :key="index">
                        <span>{{ tracking.date }}</span>
                        <template v-if="tracking.error && tracking.error != 'undefined : undefined'">
                            <font-awesome-icon class="text-danger ml-1" :id="'tooltip-target-'+data.invoice_id" :icon="['fal', 'exclamation-triangle']"/>
                            <b-tooltip placement="right" :target="'tooltip-target-'+data.invoice_id" triggers="hover" style="min-width:500px; !important">
                                {{ tracking.error }}
                            </b-tooltip>
                        </template>
                    </div>
                    <template v-if="data.mandrill_tracking.length == 0 && data.invoice_send_mail">
                       {{ $t('global.en_cours') }} <font-awesome-icon :icon="['far', 'hourglass']"/>
                    </template>
                </template>
			</CustomTable>
        </div>

        <b-modal ref="modalPayment" hide-footer size="xxl">
            <template v-slot:modal-title>
                    {{ $t("payment.add_payment") }}
            </template>

            <AddPayment ref="addPayment" :invoice_ids="invoice_ids" :invoice_accountingplans="invoice_accountingplans" :tiers_id="tiers_id" :invoice_nums="invoice_nums" :invoice_balances="invoice_balances" :processing.sync="processing" :ready.sync="ready" :submit_payment.sync="submit_payment"></AddPayment>

            <div class="d-none col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

        <b-modal size="xl" ref="modalAvoir" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("invoice.add_avoir") }}
            </template>

            <AddAvoir ref="addAvoir" :only_on_baf="only_on_baf" :facture_avoir="facture_avoir" :processing.sync="processing" :ready.sync="ready" :invoiceable.sync="invoiceable"></AddAvoir>

            <div class="col-4 m-auto">
                <b-button v-if="ready && invoiceable" block pill variant="primary" @click="submitAvoir(false)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.valider") }}</b-button>
                <b-button v-if="ready" block pill variant="primary" @click.prevent="submitAvoir(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.valider_en_baf") }}</b-button>
            </div>
        </b-modal>

        <ModalSendInvoice ref="modal_send_invoice" @submit="stopSpin"/>
        <ModalEditQualification ref="modal_edit_qualification"/>
    </div>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus';
    import Common from '@/assets/js/common.js'
    import Config from "@/mixins/Config.js"
    import Document from "@/mixins/Documents.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import TableAction from "@/mixins/TableAction.js"
    import Shutter from '@/mixins/Shutter.js'
	import ShutterInvoice from '@/mixins/shutters-manager/Invoice.js'
    import Tiers from '@/mixins/Tiers.js'
    import Tools from '@/mixins/Tools.js'
    import _groupBy from 'lodash/groupBy'
    
    export default {
        name: "list",
        mixins: [Config, TableAction, Invoice, Navigation, Payment, Shutter, ShutterInvoice, Tiers, Tools],
        props: {
            options: {
                type: Object,
                default: () => ({
                    type: 'Invoice',
                    id: null,
                })
            },
            horse_id: {
                type: String,
                default: () => ''
            }
        },
        data () {
            return {
				id_table: "invoices",
                invoices: null,
                table_busy: true,
                modal_loaded: false,
                only_on_baf: false,
                ready: false,
                invoiceable: true,
                processing: false,
                config_table_hrefs: {
                    'tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche_1',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    },
                    'author.tiers_rs': {
                        routeUniqueName: 'tiersFiche_2',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'author.tiers_id'
                        }
                    }
                },
                facture_avoir: {},
                invoice_ids: null,
                tiers_id: 0,
                base64: null,
                invoice_accountingplans: null,
                from: '',
                invoice_nums: null,
                invoice_balances: 0,
                rawColumnNames: ['tiers.tiers_rs', 'author.tiers_rs', 'invoice_ht','invoice_balance','invoice_ttc','invoice_vat', 'tracking_envoie_date', 'received_date'],
				extern_slot_columns: ['invoice_exported', 'invoice_printed', 'invoice_renew', 'preview', 'contracts', 'horses', 'trackings_link', 'tracking_envoie_date'],
                events_tab: {
                    'TableAction::goToDuplicateInvoice': this.saveDuplicateInvoice,
                    'TableAction::goToDownloadPdfInvoice': (invoice_ids) => {
						this.downloadPdf(invoice_ids)
					},
                    'TableAction::goToPdfInvoice': this.savePdfInvoice,
                    'TableAction::goToPdfInvoiceWithAdvancedPayment': (params) => {
                        this.savePdfInvoice(params, true)
                    },
                    'TableAction::goToSendInvoice': (params) => {
                        // this.invoice_ids = params.invoice_ids

                        //Temporairement 
                        let unique_ids = new Set(params.author_ids)
						if(unique_ids.size > 1) {
							this.infoToast("toast.model_choice")
						}
						if(unique_ids.size == 1) {
							this.openModalSendInvoice(params.invoice_ids, unique_ids)
						}
						else {
							this.callSendInvoice(params.invoice_ids)
						}
                    },
                    'TableAction::goToAddPayment': this.check_display_payment_form,
                    'TableAction::goToDeleteInvoice': (params) => {
                        this.goToDeleteInvoices(params).then(() => {
                            this.table_busy = true
                            this.$refs[this.id_table].unselectAll()
                            this.loadInvoices()
                        })
                    },
                    'TableAction::goToExportComptable': this.export_comptable,
                    'TableAction::goToModalFactureAvoir': this.modalFactureAvoir,
					'TableAction::goToAccounted': (invoice_ids) => {
						this.accounted(invoice_ids)
					},
					'TableAction::goToNoPrint': (invoice_ids) => {
						this.no_print(invoice_ids)
					},
					'TableAction::goToModifyInvoiceSaillie': (invoice_ids) => {
                        this.$refs[this.id_table].unselectAll()
						this.updateInvoiceSaillie(invoice_ids).then(() => {
                            this.table_busy = true
                            this.loadInvoices()
				        })
					},
                    'TableAction::goToSendMailCAInvitation': this.sendMailCAInvitation,
                    'TableAction::goToEditQualificationTiers': (invoices) => {
                        this.openQualificationModal(invoices)
					},
                    'close_modal': this.closeAvoir
                },
                tiers: {},
                start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
                end_date: new Date(),
                vat: [],
                stats_loaded: true,
                stat: [],
                fields_stat: [
                    {key: 'accounting_plan', label: this.getTrad('compta.title_page'), sortable: true},
                    {key: 'total_ht', label: this.getTrad('invoice.stat_total_ht'), sortable: true, tdClass: 'text-right'},
                    {key: 'total_tva_55', label: this.getTrad('invoice.stat_total_tva_55'), sortable: true, tdClass: 'text-right'},
                    {key: 'total_tva_10', label: this.getTrad('invoice.stat_total_tva_10'), sortable: true, tdClass: 'text-right'},
                    {key: 'total_tva_20', label: this.getTrad('invoice.stat_total_tva_20'), sortable: true, tdClass: 'text-right'},
                    {key: 'total_ttc', label: this.getTrad('invoice.stat_total_ttc'), sortable: true, tdClass: 'text-right'}
                ],
                submit_payment: false
            }
		},
        mounted() {
            this.from = this.$route.name
            this.init_component()
        },
        methods: {
            async init_component() {
                if(this.getConfig('startDateListInvoice')) {
                    this.start_date = new Date(this.getConfig('startDateListInvoice'))
                }
                if(this.getConfig('endDateListInvoice')) {
                    this.end_date = new Date(this.getConfig('endDateListInvoice'))
                }
                if(this.horse_id) {
                    this.loadInvoicesWithoutDate()
                }
            },
            async loadInvoices() {
				this.saveDateFilters()
                this.table_busy = true
				
				let invoices
                // if(this.from == 'HorseFicheInvoice')
                if(this.options.type == "Contract")
                {
                    invoices = await this.loadInvoiceContract(this.options.id)
                }
                else
                {
                    invoices = await this.loadInvoice(this.start_date, this.end_date)
                }
                this.invoices = await this.formatInvoices(invoices)

                this.loadStats()

                this.table_busy = false
            },
            async loadInvoicesWithoutDate() {
                this.table_busy = true
                const invoices = await this.loadInvoiceHorse(this.horse_id)
                this.invoices = await this.formatInvoices(invoices)
                this.loadStats()
                this.table_busy = false
            },
            async formatInvoices(invoices) {
                let invoicesFormated = []

                if(!invoices)
                {
                    return []
                }
                
                let date_lang_format = Vue.prototype.$i18n.locale()

                for (let i = 0; i < invoices.length; i++) {
                    const temp = invoices[i]
                    temp.href = "tiersFiche"
                    temp.params = { tiers_id: temp.tiers.tiers_id }

                    const tracking_mail = temp.tracking.filter(tracking => tracking.invoicetracking_type == "mail" || tracking.invoicetracking_type == "autre")

                    temp.tracking_envoie_date = this.getArrayObjProperty(tracking_mail, "invoicetracking_date")

                    if(temp.tracking_envoie_date.length > 0) {
                        temp.tracking_envoie_date = temp.tracking_envoie_date.map(date => this.formatDate(date)).join('<br>')
                    }

                    if(temp.invoice_state != "complete" && temp.invoice_send_mail === true){
                        
                        temp.tracking_envoie_date = this.getTrad("global.en_cours")
                    }
                    if(temp.tracking_envoie_date.length == 0)
                    {
                        temp.tracking_envoie_date = ""
                    }
                    

                    const tracking_received = temp.tracking.filter(tracking => tracking.invoicetracking_type == "received")
                    temp.received_date = tracking_received.length > 0 ? tracking_received[tracking_received.length - 1].invoicetracking_date : ''
                    // temp.received_date = this.getArrayObjProperty(tracking_received, "invoicetracking_date")
                    // if(temp.received_date.length == 0) {
                    //     temp.received_date = ""
                    // }

                    // temp.mandrill_tracking = temp.mandrill_tracking ? temp.mandrill_tracking.map(tracking => {
                    //     return  tracking.mandrilltracking_status+ ' : '+ tracking.mandrilltracking_error
                    // }).join('<br>') : ''

                    const mandrill_tracking = temp.mandrill_tracking ? temp.mandrill_tracking.map(tracking => {
                        const res = {}
                        const queued = tracking.find(tr => tr.mandrilltracking_status == 'queued')
                        if(queued) {
                            res.date = this.formatDate(queued.mandrilltracking_inserted)
                        }
                        const bounce = tracking.find(tr => tr.mandrilltracking_status == 'hard_bounce' || tr.mandrilltracking_status == 'soft_bounce')
                        if(bounce) {
                            res.error = bounce.mandrilltracking_status+ ' : '+ bounce.mandrilltracking_error
                        }

                        return res
                    }) : []

                    temp.mandrill_tracking = mandrill_tracking
                    
                    // temp.invoice_ttc_vanilla = temp.invoice_ttc
                    // temp.invoice_ttc = await this.priceFormat(temp.invoice_ttc, temp.author.tiers_currency)
                    // temp.invoice_ht_vanilla = temp.invoice_ht
                    // temp.invoice_ht = await this.priceFormat(temp.invoice_ht, temp.author.tiers_currency)
                    // temp.invoice_balance_vanilla = temp.invoice_balance
                    // temp.invoice_balance = await this.priceFormat(temp.invoice_balance, temp.author.tiers_currency)
                    // temp.invoice_vat_vanilla = temp.invoice_vat
                    // temp.invoice_vat = await this.priceFormat(temp.invoice_vat, temp.author.tiers_currency)
                    temp.customClasses={
                        invoice_ht:"text-right",
                        invoice_vat:"text-right", 
                        invoice_ttc:"text-right",
                        invoice_balance:(temp.invoice_balance>0)?"text-right red":"text-right"
                    }

                    temp.contracts = []
                    temp.horses = []
                    temp.tva_intra = false
                    temp.tva_tiers = false
                    if(temp.details) {
                        temp.details.forEach(detail => {

                            if(detail.invoicedetails_codevat == "FDVEX"){temp.tva_tiers = true}
                            if(detail.invoicedetails_codevat == "FDVXP"){temp.tva_intra = true}

                            if(detail.contract && !temp.contracts.find(c => c.contract_id == detail.contract.contract_id)) {
                                temp.contracts.push({
                                    contract_id: detail.contract.contract_id,
                                    contract_num: detail.contract.contract_num
                                })
                            }
                            if(detail.horse && !temp.horses.find(h => h.horse_id == detail.horse.horse_id)) {
                                temp.horses.push({
                                    horse_id: detail.horse.horse_id,
                                    horse_name: detail.horse.horse_nom
                                })
                            }
                        })
                        temp.formatted_horses = temp.horses.map(horse => horse.horse_name).join(', ')
                    }

                    invoicesFormated.push(temp);
                }
                return invoicesFormated
            },
            checkPaymentFormFunction(params){
                this.tiers_id = 0
                if(this.checkPaymentForm(params))
                {
                    this.$refs['modalPayment'].show()
                }
            },

            async onSubmit(){
                if(!this.processing)  {
                    this.processing = true
                    let return_payment = await this.$refs['addPayment'].addPayment()
                    if(return_payment) {
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "coordonneesTiers", val: true })
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "Invoice", val: true })
                        
                        this.$refs[this.id_table].unselectAll()
                        this.$refs["modalPayment"].hide()
                        this.table_busy = true
                        this.tiers_id = 0
                        await this.loadInvoices()
                    }
                }
            },

            async callSendInvoice(invoice_ids) {
                await this.sendMailInvoices(invoice_ids)
                EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
            },

            async onSend(){
                let return_send = await this.$refs['sendInvoice'].sendInvoice()

                if(return_send)  {
                    this.$refs["modalSend"].hide()
                    this.table_busy = true
                    await this.loadInvoices()
                }
            },

            async modalFactureAvoir(facture){
                this.facture_avoir = facture
                this.$refs['modalAvoir'].show()
            },

            async submitAvoir(only_baf = false){
                this.only_on_baf = only_baf
                await this.$refs['addAvoir'].checkIfFirstInvoiceMonth(only_baf)
                this.$refs[this.id_table].unselectAll()
            },

            async closeAvoir(){
                this.$refs['modalAvoir'].hide()
                this.table_busy = true
                await this.loadInvoices()
            },

            async export_comptable(params) {
                let invoice_ids = params.invoice_ids
                await this.loadExportComptable(invoice_ids.split(","))
                EventBus.$emit('TableAction::stopSpin')
            },
            async saveDuplicateInvoice(params) {
                await this.duplicateInvoice(params.invoice_ids)
                EventBus.$emit('TableAction::stopSpin')
            },
			async savePdfInvoice(params, acompte = false) {
				const platform = Common.getActualPlatformEvo()
				const tab_ids = params.invoice_ids.split(',')

				if(platform != "regular" && Array.isArray(tab_ids) && tab_ids.length > 1) {
					this.failureToast('invoice.fonction_indispo_plateforme')
					return
				}

				if(platform != "regular") {
                    let resultPdf = null
                    if(acompte) {
                        resultPdf = await this.pdfInvoiceWithAdvancedPayment(params.invoice_ids, params.invoice_nums, true)
                    }
                    else {
                        resultPdf = await this.pdfInvoice(params.invoice_ids, params.invoice_nums, true)
                    }

					const base64Cleanedup = this.formatBase64String(resultPdf)

					this.previewPdf(base64Cleanedup, () => {
						EventBus.$emit('TableAction::stopSpin')
					})
				}
				else {
                    if(acompte) {
                        this.pdfInvoiceWithAdvancedPayment(params.invoice_ids, params.invoice_nums).then(() => {
                            EventBus.$emit('TableAction::stopSpin')
                            this.table_busy = true
                            this.loadInvoices()
                        })
                    }
                    else {
                        this.pdfInvoice(params.invoice_ids, params.invoice_nums).then(() => {
                            EventBus.$emit('TableAction::stopSpin')
                            this.table_busy = true
                            this.loadInvoices()
                        })
                    }
				}
			},

            async downloadPdf(invoice_ids) {
				const platform = Common.getActualPlatformEvo()

				if(platform != "regular" && Array.isArray(invoice_ids) && invoice_ids.length > 1) {
					this.failureToast('invoice.fonction_indispo_plateforme')
					return
				}

				if(platform != "regular") {
					const resultPdf = await this.downloadPdfInvoice(invoice_ids)
					const base64Cleanedup = this.formatBase64String(resultPdf)

					this.previewPdf(base64Cleanedup, () => {
						EventBus.$emit('TableAction::stopSpin')
					})
				}
				else {
					this.downloadPdfInvoice(invoice_ids).then(() => {
						EventBus.$emit('TableAction::stopSpin')
					})
				}
			},
			
			saveDateFilters() {
                this.setConfig('startDateListInvoice', this.start_date)
                this.setConfig('endDateListInvoice', this.end_date)
			},

			accounted(invoice_ids) {
				this.accounted_invoices(invoice_ids)
				.then(() => {
					this.table_busy = true
					this.loadInvoices()
				})
			},

			no_print(invoice_ids) {
				this.notPrintedInvoice(invoice_ids)
				.then(() => {
					this.table_busy = true
					this.loadInvoices()
				})
			},

            async loadStats() {
                this.stats_loaded = false
                this.stat = []

                if(this.invoices.length === 0) {
                    this.stats_loaded = true
                    return false
                }

                const invoice_ids = this.invoices.map(invoice => invoice.invoice_id)

                const stats = await this.loadInvoiceStat(invoice_ids)

                await Common.asyncForEach(stats, async (stat) => {
                    this.stat.push({
                        accounting_plan:stat.accounting_plan,
                        total_ht:       await this.priceFormat(stat.total_ht, stat.currency.currency_code, true),
                        total_tva_55:   await this.priceFormat(stat.total_tva_55, stat.currency.currency_code, true),
                        total_tva_10:   await this.priceFormat(stat.total_tva_10, stat.currency.currency_code, true),
                        total_tva_20:   await this.priceFormat(stat.total_tva_20, stat.currency.currency_code, true),
                        total_ttc:      await this.priceFormat(stat.total_ttc, stat.currency.currency_code, true)
                    })
                })

                this.stats_loaded = true
            },

			openQualificationModal(invoices) {
                const tiers_ids = invoices.map((invoice) => invoice.invoice_tiers)
				this.$refs.modal_edit_qualification.openModalFromTiers(tiers_ids)
			},

            openModalSendInvoice(invoice_ids, unique_author_id) {
				let author_id = unique_author_id.values().next().value
				this.$refs.modal_send_invoice.openModal(invoice_ids, author_id)
			},

            stopSpin() {
				EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
			},

            async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },

            openShutterTrackings(invoice_id) {
                this.openTrackingsShutter(invoice_id)
            },

            async sendMailCAInvitation(invoices) {
                const tiers = Object.keys(_groupBy(invoices, 'invoice_tiers'))
                await this.sendCAInvitation(tiers)
            },
            tdAlign(label) {
                return label === this.getTrad('compta.title_page') ? '' : 'text-right'
            }
		},
		computed: {
			horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            }
		},
        watch: {
            submit_payment() {
                this.onSubmit()
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            SendInvoice: () => import('@/components/Invoice/SendInvoice'),
            AddPayment: () => import('@/components/Payment/AddPayment'),
            AddAvoir: () => import('@/components/Invoice/AddAvoir'),
            ModalConfirm: () => import("GroomyRoot/components/Modals/ModalConfirm"),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
            ModalSendInvoice : () => import('@/components/Tiers/ModalSendInvoice'),
            ModalEditQualification: () => import('@/components/Contract/ModalEditQualification')
        }
    }
</script>
